:root{
    --background-item-login: rgb(0 38 77 / 70%);
    --white-font: #fff;
    --blue-font-login: #01579b;
    --background-transparent: rgb(227 227 227 / 60%);
    --background-routerActive: #294adb;
    --color-gray:#333;
    --font-gray: #808080;
    --background-routerActive-submenu: #0020ab;
    --backgroudn-tile-footer: rgba(255,255,255,.623);
    --color-font-tile-footer: #294adbce;


    // VARIABLES DE ALUMBRADO
    --backgroud-blue: #537eab;
    --tab-active: #466788;
    --background-btn: #344D67;
    --background-expansion-header: #6b93bf;
    --background-expansion-body: #7ea6d1;
    --checkbox-selected: #6f97bf;
    --color-font: #2C3E50;
    --tab-active-second: #7ea6d169;
}
