/*** ESTILOS MAPA LEAFLET ***/

@import "~leaflet/dist/leaflet.css";
@import "~leaflet-draw/dist/leaflet.draw.css";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&display=swap');
/*@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";*/


/*@import "~leaflet.markercluster/dist/MarkerCluster.css";*/

html,
body {
    height: 100%;
    width: 100%;
    overflow: hidden !important;
    margin: 0;
    font-family: 'Montserrat';
}

::ng-deep .mat-calendar-body-cell {
    position: relative !important;
}


.SCTable {
    overflow: auto !important;
    max-height: 70vh;
    margin: 0.6rem;
}

// scroll custom
.custom-scrollbar-color-gray::-webkit-scrollbar-thumb {
    box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
    border-radius: 100px;
}

.custom-scrollbar-color-gray::-webkit-scrollbar {
    width: 16px;
}
  
.custom-scrollbar-color-gray::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
}

.custom-scrollbar-color-gray::-webkit-scrollbar-thumb {
    background-color: #adadad;
    border-radius: 100px;
}
// 

// Estilo para el formato de notificaciones de matricula
.format-notification-plate {

    .notification-title {
        color:#362166;
        font-size: 20px;
        text-align: center;
        border-bottom: 1px solid #eee;
        padding-bottom: 10px;
    }

    .swal2-footer{
        p {
            font-size: 10px !important;
        }
        justify-content: space-between;
    }

    .info-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        span{
            font-weight: bold;
            font-size: 14px;
        }
        p {
            color: #362166;
            font-size: 14px;
        }
    }

    .info-row {
        padding: 10px 0px;
        display: flex;
        justify-content: space-between;
    }

    .swal2-actions {
        margin: 0;
    }

    .img-container{
        padding: 10px 0;
        min-height: 200px;
        img {
            width: 100%;
        }
    }

    p {
        font-size: 14px;
    }

}


.panel {
    position: fixed;
    top: 45px;
}

.colortooltip {
    background: #01579b;
    font-size: 12px;
}

.colortooltipazul {
    background: #376092;
    font-size: 12px;
}

.colortooltipnaranja {
    background: #fbc02d;
    font-size: 12px;
}

.colortooltiprojo {
    background: #fb2d2d;
    font-size: 12px;
}

.hidden {
    display: none;
}

.mat-form-field-underline {
    display: none;
}

.mat-dialog-width-transition {
    transition: width 0.5s;
}

mat-calendar .mat-calendar-body-cell {
    height: 100%;
    position: absolute;
}

/*@import 'tema.scss';*/

.popover {
    max-width: fit-content;
}

.animacion {
    width: 100%;
    text-align: -webkit-center;
    /*  height: 90px;*/
}

@media screen and (max-width:700px) {
    .popover {
        left: 171.172px;
        top: 0px !important;
        overflow-y: auto;
        margin-left: auto;
        opacity: 1;
        display: block;
        max-inline-size: -webkit-fill-available;
        text-align: start;
    }
    .mat-calendar {
        width: 100% !important;
    }
}

.mat-calendar-header {
    padding: 8px 8px 0 8px;
    /* background-color: #01579b;*/
    /*color: #fff;*/
}


/*--------------------TOOLTIP--------------------------*/

.div_G {
    color: #333333;
    line-height: 13px;
    font-weight: 500;
    font-family: Roboto, Segoe UI, wf_segoe-ui_normal, helvetica, arial, sans-serif;
    font-size: 12px;
}

.tr_encabezado {
    padding: 3px;
    background-color: #fff;
}

.div_encabezado {
    color: #333;
    text-align: left;
    line-height: 13px;
    font-weight: bold !important;
    font-family: Roboto, Segoe UI, wf_segoe-ui_normal, helvetica, arial, sans-serif;
    font-size: 12px;
    margin-bottom: 0.5rem;
}

.td_titulo {
    border: 2px solid #fff;
    padding: 3px;
    background-color: #fff;
}

.div_titulo {
    color: #333;
    text-align: left;
    line-height: 13px;
    font-weight: 500;
    font-family: Roboto, Segoe UI, wf_segoe-ui_normal, helvetica, arial, sans-serif;
    font-size: 12px;
    margin-bottom: 0px;
}

.tr_contenido {
    vertical-align: text-top;
}

.td_col_1 {
    border: 2px solid #fff;
    padding: 3px;
    background-color: #ccc;
}

.td_col_1_ac {
    border: 2px solid #fff;
    padding: 3px;
    background-color: #929292;
}

.color_0 {
    color: #222222;
}

.td_div {
    line-height: 19px;
    font-weight: bold;
    font-family: Roboto, Segoe UI, wf_segoe-ui_normal, helvetica, arial, sans-serif;
    font-size: 18px;
}

.label_G {
    font-size: 12px;
    margin-bottom: 0px;
}

.color_1 {
    color: #362166;
}

.color_2 {
    color: #643CC1;
}

.color_3 {
    color: #95DC08;
}

.color_4 {
    color: #222222;
}

.color_5 {
    color: #FF9E61;
}

.color_6 {
    color: #7DD1F5;
}

.color_7 {
    color: #9e9d24;
}

.color_8 {
    color: #fbc02d;
}

.color_9 {
    color: #362166;
}

.td_col_G {
    border: 2px solid #fff;
    padding: 3px;
    background-color: #fff;
    text-align: center;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: rgba(0, 0, 0, 0.00);
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background, 
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background
{
    background-color: #01579b !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: #01579b !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #01579b !important;
}


/* .slider {} */


/*--------------------TOOLTIP--------------------------*/

.tootipPersonal {
    margin-top: -17px !important;
    background: #3500FF;
    font-size: 12px;
    font-weight: 600;
    height: 16px;
    margin: 0;
    padding-top: 0px;
}

.contextMenu {
    border: 1px solid gray;
    min-width: 125px;
    list-style: none;
    display: block;
    list-style-position: outside;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.contextMenu li {
    cursor: pointer;
    display: block;
    padding: 6px 12px;
}

.contextMenu li:focus,
.contextMenu li:hover {
    background-color: rgba(0, 0, 0, .05);
}

.contextMenu a,
.contextMenu a:hover,
.contextMenu a:focus,
.contextMenu a:active {
    text-decoration: none;
    color: black;
    font-size: 14px;
}

.items-overlay-img,
.items-overlay-reproductor,
.items-overlay,
.items-overlay-map {
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    transition: .2s opacity 0s ease-in-out, 0s visibility .2s ease-in-out;
    visibility: hidden;
    width: 100%;
    z-index: 9998;
}

.items-overlay-sidebar {
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    transition: .2s opacity 0s ease-in-out, 0s visibility .2s ease-in-out;
    visibility: hidden;
    width: 100%;
    z-index: 9996;
}

.loading-map {
    position: absolute;
    top: 50%;
    left: 50%;
}

.pointer {
    cursor: pointer;
}

/*-------------FUNCIONES FLOTANTE-----------------*/

.mostrarBusqueda {
    position: static !important;
    z-index: 0 !important;
    margin-top: 0px !important;
}

.ocultarBusqueda {
    position: absolute !important;
    z-index: -1 !important;
    margin-top: -100% !important;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

#btn-mas {
    display: none;
}

.container {
    position: fixed;
    bottom: 20px;
    right: 2px;
    text-align: -webkit-right;
}

.funcionesMenu a,
.icon-mas2 {
    display: block;
    text-decoration: none;
    background: #01579b;
    color: #fff;
    width: 55px;
    height: 55px;
    line-height: 55px;
    text-align: center;
    border-radius: 50%;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.4);
    transition: all 500ms ease;
    font-size: 30px;
}

.funcionesMenu a:hover {
    background-color: #fff;
    color: #01579b;
}

.funcionesMenu a {
    margin-bottom: -15px;
    opacity: 0;
    visibility: hidden;
}

#btn-mas:checked~.funcionesMenu a {
    margin-bottom: 10px;
    opacity: 1;
    visibility: visible;
}

.icon-mas2 {
    cursor: pointer;
    background: #01579b;
    font-size: 30px;
}

.tr_encabezado {
    font-weight: bold;
}

#btn-mas:checked~.btn-mas .icon-mas2 {
    transform: rotate(90deg);
    font-size: 30px;
}


/*------------- FIN FUNCIONES FLOTANTE-----------------*/


/*------------- ESTILOS QUE SE REPITEN -----------------*/

.preset-icon {
    color: #01579b;
}

.preset-icon:hover {
    color: rgba(1, 87, 155, 0.66);
}

.background-expand-img {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    pointer-events: auto;
    -webkit-tap-highlight-color: transparent;
    -webkit-transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
    transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
    opacity: 1;
    background: rgba(0, 0, 0, 0.32);
    transform: scale(1.5);
}

.footer-matriculas-extend {
    background-color: rgb(0 0 0 / 68%) !important;
}

.button-download {
    background: #01579b;
    color: white;
    margin-bottom: 10px;
}

.button-download:disabled {
    color: #eee;
    background: #ccc;
}

.button-war {
    background: #f19263;
    color: white;
    margin-bottom: 10px;
}

.button-war:disabled {
    color: #eee;
    background: #ccc;
}

.button-warning {
    background: #f44336;
    color: white;
    margin-bottom: 10px;
}

.button-warning:disabled {
    color: #eee;
    background: #ccc;
}

.text-uppercase {
    text-transform: uppercase;
}

.download-header {
    position: absolute;
    top: 0;
    right: 15px;
}

.download-img {
    color: white;
}

.download-img:hover {
    color: #d2cece;
}

.button-center {
    text-align: center;
    padding: 10px 0;
    overflow: hidden;
}

.button-global {
    color: #fff;
    margin: 2px !important;
}


/*** ESTILOS MAPA LEAFLET ***/

.leaflet-draw-toolbar .leaflet-draw-draw-polygon {
    background: url('assets/imagenes/map/icons/polygon.png') no-repeat;
    background-color: #FFF;
    background-size: 30px;
    background-repeat: round;
}

.leaflet-draw-toolbar .leaflet-draw-draw-marker {
    background: url('assets/imagenes/map/icons/marker-draw.png') no-repeat;
    background-color: #FFF;
    background-size: 24px;
    background-position-x: center !important;
    background-position-y: center !important;
}

.leaflet-draw-toolbar .leaflet-draw-draw-polyline {
    background: url('assets/imagenes/map/icons/polyline.png') no-repeat;
    background-color: #FFF;
    background-size: 24px;
    background-position-x: center !important;
    background-position-y: center !important;
}

.leaflet-draw-toolbar .leaflet-draw-draw-circle {
    background: url('assets/imagenes/map/icons/circle.png') no-repeat;
    background-color: #FFF;
    background-size: 24px;
    background-position-x: center !important;
    background-position-y: center !important;
}

.leaflet-draw-toolbar .leaflet-draw-edit-edit {
    background: url('assets/imagenes/map/icons/edit-blue-draw.png') no-repeat;
    background-color: #FFF;
    background-size: 24px;
    background-position-x: center !important;
    background-position-y: center !important;
}

.leaflet-draw-toolbar .leaflet-draw-edit-remove {
    background: url('assets/imagenes/map/icons/delete-blue-draw.png') no-repeat;
    background-color: #FFF;
    background-size: 24px;
    background-position-x: center !important;
    background-position-y: center !important;
}

.dialogoPer {
    padding: 0px;
}

.colorVehiculosTramo {
    color: #0020AB;
}

.backgroundTamo,
.backgroundTamoEspaciado {
    background-color: #fcfcfc;
    text-align: center;
}

.backgroundTamo {
    border: 2px solid #fff;
    padding: 3px;
}

.colorDistintosTramo {
    color: #7DD1F5;
}

.backgroundTamoEspaciado {
    border-left: 2px solid #fff;
}

.colorVelMaxTramo {
    color: #E53935;
}

.colorVelMedTramo {
    color: #FB8C00;
}

.colorVelMinTramo {
    color: #95DC08;
}

.TxtNegrilla {
    font-weight: 600;
}

.mat-drawer-backdrop {
    height: 100% !important;
    left: 0 !important;
    position: fixed !important;
    top: 0 !important;
    width: 100% !important;
    z-index: 3 !important;
    background: rgba(60, 78, 98, .2) !important;
    opacity: 1 !important;
    visibility: hidden !important;
    /* pointer-events:none !important; */
}

.mat-dialog-container {
    padding: 0px !important;
    margin: 0px !important;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
}

.mat-drawer-inner-container {
    overflow: hidden;
    overflow-x: hidden !important;
    padding: 5px;
    width: 360px;
    /* height:92%; */
}

.map-copyright {
    visibility: hidden !important;
}

.bottom-right.subcontrol-container {
    bottom: 0px !important;
    /* background-color: #95DC08; */
    height: 150px !important;
}

.mat-slider-track-fill, .mat-slider-thumb, .mat-slider-thumb-label-text, .mat-slider-thumb-label {
    background-color: #294adb !important;
    color: #ffff !important;
}

.mat-slider-thumb-label {
    transform: rotate(45deg) !important;
    border-radius: 50% 50% 0 !important;
  }

.mat-slider-thumb {
    transform: scale(0) !important;
}

.mat-slider-thumb-label-text {
    opacity: 1 !important;
}

.w-full {
    width: 100%!important;
}

.popover {
    font-family: inherit;
}

.justify-center {
    justify-content: center !important;
}
.contentBtns_select{
    .mat-button-toggle-label-content{
        padding: 0 !important;
    }
}

/** quitar padding bottom mat-form-field */
.divApi .mat-form-field-wrapper{
    padding-bottom: 0px !important;
}

/* Estilos mapa info matricula */
  
.check1 .mat-checkbox-checkmark {
    background-color: #227D00 !important;
}
.check2 .mat-checkbox-checkmark {
    background-color: #FFD500 !important;
}
.check3 .mat-checkbox-checkmark {
    background-color: #FF0000 !important;
}

/* calendars styles */
.calendar-date {
    font-size: 16px !important;
    font-weight: 500 !important;
    font-family: Roboto, Segoe UI, wf_segoe-ui_normal, helvetica, arial, sans-serif !important;
}

.width-max-mat-dialog {
    height: auto !important;
    align-items: 'center' !important;
}

/*Estilos table*/
.contentTableStyle {
    display: block !important;
    overflow-x: auto !important;
    table, .table, .mat-table{
        overflow-x: auto !important;
        width: 100% !important;
        text-align: center !important;
        text-align-last: center !important;

        .mat-header-cell{
            font-weight: bold !important;
        }

        .mat-header-cell,
        .mat-cell {
            font-size: small;
            padding-left: 2em !important;
            padding-right: 2em !important;
            text-align: center !important;
        }
    }
} 

/*calendario border inputs minutes seconds*/
.ngx-mat-timepicker-table{
    .mat-form-field {
        border-bottom: 1px solid gray !important;
    }
}

/** Estilos swal container*/
.container-swal-customclass{
    background: #ededed !important;
}
.swal2-html-container{
    overflow: hidden !important;
}

/** Color icon close*/
.iconoBusqueda {
    color: #294adb;
    cursor: pointer;
}

/** Estilos table container*/
.divTableContainer {
    margin: 2em;
    display: block !important;
    overflow-x: auto !important;

    table, .table, .mat-table {
        width: 100% !important;
        text-align: center !important;
        overflow-x: auto !important;
        text-align: center !important;
        text-align-last: center !important;

        .mat-header-cell {
            font-weight: bold !important;
            justify-content: center !important;
        }

        .mat-cell {
            cursor: pointer;
            overflow: hidden !important;
            text-overflow: ellipsis;
            display: inline-block;
            font-size: small;
            text-align: center !important;
            align-content: center;
        }

        .mat-header-cell,
        .mat-cell {
            padding-left: 1em !important;
            padding-right: 1em !important;
        }
    }
}

/*----------------------------Responsive-----------------------------*/

@media (max-width: 768px) {

    .container {
        display: block;
    }

    .mat-form-field-appearance-legacy .mat-form-field-wrapper {
        padding: 0px;
    }

    .width-max-mat-dialog {
        max-width: 98% !important;
        width: 98% !important;
        height: auto !important;
    }

    .calendar-date {
        font-size: 14px !important;
    }

    .mat-paginator-container {
        justify-content: center !important;
    }
    
    .mat-datepicker-content {
        width: 256px;
    }

    .mat-form-field-appearance-fill .mat-form-field-flex {
        padding: 0px !important;
        text-align: center !important;
    }

    .mat-form-field-wrapper {
        padding-bottom: 0em;
    }

    .divTable {
        display: block !important;
        overflow-x: auto !important;
        table, .table, .mat-table{
            overflow-x: auto !important;
            width: 100% !important;
            text-align: center !important;
            text-align-last: center !important;

            .mat-header-cell{
                font-weight: bold !important;
            }

            .mat-header-cell,
            .mat-cell {
                font-size: small;
                padding-left: 2em !important;
                padding-right: 2em !important;
                text-align: center !important;
            }

            .mat-cell{
                span{
                    min-width: 3em !important;
                }
            }
        }
    } 

    .sinData{
        top: 13em;
        width: 100%;
        height: 80%;
        display: flex;
        font-size: larger;
        font-weight: bold;
        position: absolute;
        align-items: center;
        flex-direction: column;
        
        mat-icon{
            width: 60px;
            height: 60px;        
            font-size: 60px;
        }
    }

    
    // paginator
    .paginator_Mobile{
        width: 100%;
        display: flex;
        font-size: smaller;
        align-items: center;
        justify-content: center;
        .mat-paginator-range-label{
            display: contents !important;
        }
    }

    .bottom-left.subcontrol-container{
        height: 80% !important;
    }

    .atlas-legend-gradient-legend{
        svg{
            width: 60px !important;
            height: 380px !important;
        }
    }
    
    .atlas-layer-legend-card{
        min-width: auto !important;
    }
}

.divFusionCharts{
    .lineatitulo{
        display: flex;
        justify-content: space-between;
        
        mat-icon{
            margin-right: 10px;
        }
    }
}

.content-menu-izquierdo{
    .mat-form-field-wrapper{
        margin: 0 !important;
        padding: 0 !important;
    }
}

@media screen and (min-width: 768px) {
    .container {
        display: none;
    }
    #contenedorF {
        position: static !important;
        z-index: 0 !important;
        margin-top: 0px !important;
    }
}

.mat-row-no-data {
    td {
        text-align: center;
        padding: 30px;
        font-size: 28px;
    }
}


.mat-calendar-arrow {
    border: none !important;
    width: 10px !important;
    height: 5px !important;
  }
  ngx-mat-calendar-header .mat-calendar-arrow {
    border-left: 5px solid transparent !important;
    border-right: 5px solid transparent !important;
    border-top-width: 5px !important;
    border-top-style: solid !important;
    width: 0 !important;
    height: 0 !important;
  }
  ngx-mat-month-view .mat-calendar-body-cell {
    position: relative !important;
  }

  .no-padding {
    .mat-form-field-wrapper {
      padding-bottom: 0 !important;
    }
  }

  .atlas-layer-legend-expand-btn {
    display: none;
  }

  .atlas-layer-legend-card {
    max-height: 100% !important;
    min-width: 14.3em;
  }

  .hide-header .mat-card-header-text {
    display: none !important;
}

.property-text {
    font-size: 18px;
    align-self: center;
    margin-top: 1rem;
}

.popup-container {
    padding: 1rem;
    display: flex;flex-direction: column;
    font-family: 'Montserrat';

    .city-name {
        margin: 0;
        margin-bottom: 0.3rem;
        color: var(--background-routerActive-submenu);
        font-size: 3.5rem;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
    }
    .city-province {
        color: var(--background-item-login);
        font-size: 1.6rem;
        font-weight: normal;
    }

    .extrans {
        font-weight: normal;
    }
}

.popup-container-tracking {
    margin: 10px;
}

.popup-container-point-of-interest {
    margin: 10px;
    margin-top: 20px;
}

.button-icon-play-tracking {
    cursor: pointer;
    line-height: 10px;
}

.center-text {
    text-align: center;
}

// Estilos para barra superior 
.actions-groups-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-inline: 1%;
    padding-bottom: 1.5rem;

    .buttons-new-group-and-refresh-container,
    .search-container,
    .slide-toggle-container {
        flex: 1 1 100%;
    }

    .search-container {
        display: inline-flex;
        gap: 1.5rem;
        align-items: center;
        justify-content: center;

        flex: 1 1 300%;

        .nameSearchControl {
            width: 60%;
        }
    }

    .slide-toggle-container {
        display: inline-flex;
        gap: 1.5rem;
        align-items: center;
        justify-content: end;
    }
}

.popup-content-routes {
    background: white;
    color: black;
    padding: 10px;

    #title-route {
        font-size: 18px !important;
        font-weight: bold !important; 
        text-align: center !important;
        margin-bottom: 10px !important;
    }

    .subtitle{
        font-size: 15px !important;
        font-weight: bold !important;
        text-align: center !important;
    }

    .bold {
        font-weight: bold !important;
    }

    .table-container {
        overflow-x: auto;
    }

    .table {
        width: 100%;
        border-collapse: collapse;

        th, td {
            border: 1px solid black;
            padding: 8px;
            text-align: left;
        }

        th.bold {
            font-weight: bold;
        }
    }
}

.regularizacion{
    .mat-form-field-wrapper{
        padding-bottom: 0px !important;
    }
}

.selectDatosPersons{
    .mat-form-field-appearance-fill .mat-select-arrow-wrapper{
        transform: none !important;
    }
}

/** Estilos menu seleccion municipios */
.menuMunicipios{

    .mat-list-option {
        display: flex !important;
        flex-direction: row !important;
        align-items: center !important;
      
        .mat-pseudo-checkbox {
          order: -1 !important;
          margin-right: 8px !important;
        }
    }
    
    .mat-list-item-content{
        flex-direction: row !important;
    }
    
}
// Estilos de loader para usar sin el componente
.loading-animation {
    background-color: #a8a8a84f;
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0px;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: grid;
    z-index: 9999999;
  }
  
  .loader-title {
    font-weight: 900;
    color: var(--backgroud-blue);
    font-size: 25px;
  }
  
  .loader-container {
    display: flex;
    gap: 20px;
  }

/* Estilos para el visor avanzado */
.content_municipio_virtual_visorAvanzado{

    .contenedor-cabecera-municipio-grupos{
        .grupos{
            mat-tab-header{
                width: auto !important;
                border: none !important;
            }
        }
    }

    .container-fecha-muniVirtual{
        .mat-form-field-wrapper{
            padding-bottom: 7px !important;
        }
    
        .mat-form-field-infix{
            width: 7vw !important;
            text-align: center !important;
        }
    }
}

/* Estilos para tootip graficos */
.custom-tooltip-grafico-matriculas {
    #title{
        font-size: 1.5rem !important;
    }
    
    #value{
        color: #0020AB;
        font-weight: bold;
        font-size: 2rem !important;
    }
}

.contenGraficAndTable{
    .filter-row{
        display: none !important;
    }

    .estados-table-container{
        max-height: 35vh !important;
        height: 100%;
        table{
            // height: auto;
        }
    }
}
