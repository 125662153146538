@import '@angular/material/theming';
@import 'animate.min.scss';
@include mat-core();
$mat-light-bluePersonal: ( 50: #22343d, 100: #b3e5fc, 200: #81d4fa, 300: #4fc3f7, 400: #29b6f6, 500: #03a9f4, 600: #039be5, 700: #0288d1, 800: #0277bd, 900: #294ADB, A100: #80d8ff, A200: #40c4ff, A400: #00b0ff, A700: #0091ea, contrast: ( 50: $dark-primary-text, 100: $dark-primary-text, 200: $dark-primary-text, 300: $dark-primary-text, 400: $dark-primary-text, 500: $light-primary-text, 600: $light-primary-text, 700: $light-primary-text, 800: $light-primary-text, 900: $light-primary-text, A100: $dark-primary-text, A200: $dark-primary-text, A400: $dark-primary-text, A700: $light-primary-text, ));
$color_primario: mat-palette($mat-light-bluePersonal, 900);
$color_acento: mat-palette($mat-green, 50);
$candy-app-warn: mat-palette($mat-red);
$candy-app-theme: mat-light-theme($color_primario, $color_acento, $candy-app-warn);
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($candy-app-theme);
@include mat-core-theme($candy-app-theme);
@include mat-button-theme($candy-app-theme);
@include mat-checkbox-theme($candy-app-theme);
.MostrarMenu,
.animacion {
    ~.items-overlay {
        background: rgba(60, 78, 98, .2);
        opacity: 1 !important;
        transition: .2s opacity 0s ease-in-out, 0s visibility 0s ease-in-out !important;
        visibility: visible !important;
    }
}

.cargandoTabla {
    ~.items-overlay-reproductor {
        background: rgba(60, 78, 98, .2);
        opacity: 1 !important;
        transition: .2s opacity 0s ease-in-out, 0s visibility 0s ease-in-out !important;
        visibility: visible !important;
    }
}

.cargandoImg {
    ~.items-overlay-img {
        background: rgba(60, 78, 98, .2);
        opacity: 1 !important;
        transition: .2s opacity 0s ease-in-out, 0s visibility 0s ease-in-out !important;
        visibility: visible !important;
    }
}

.opensidebar {
    ~.items-overlay-sidebar {
        background: rgba(60, 78, 98, .2);
        opacity: 1 !important;
        transition: .2s opacity 0s ease-in-out, 0s visibility 0s ease-in-out !important;
        visibility: visible !important;
    }
}

.mostrarVerMas {
    ~.icon-top {
        left: 485px;
        padding: 100px 100px;
    }
}

#sidebar-content.open {
    ~#toggleButton {
        animation-duration: 1s;
        animation-name: animationToggleSidebar;
        left: 405px;
    }
}

@keyframes animationToggleSidebar {
    from {
        left: 0;
    }
    to {
        left: 405px;
    }
}

.cargandoMap {
    ~.items-overlay-map {
        background: rgba(60, 78, 98, .2);
        opacity: 1 !important;
        transition: .2s opacity 0s ease-in-out, 0s visibility 0s ease-in-out !important;
        visibility: visible !important;
    }
}
.btn-base{
    height: 24px;
    width: 109px;
    text-align: center;
    border: 1.5px solid #0020ad;
    color: #0020ad;
    font-size: 10px;
    border-radius: 5%;
    -webkit-border-radius: 5%;
    -moz-border-radius: 5%;
    -ms-border-radius: 5%;
    -o-border-radius: 5%;
}
.btn-base:hover {
    background-color: #0020ad;
    color: #fff;
    transition: 0.3s;
}
.btn-base:hover:disabled {
    background-color: rgba(0, 32, 173, 0.41);
    color: #fff;
    transition: 0.3s;
}

.swal2-popup {
    font-size: unset !important;
}

.format-pre{
    pre {
        font-family: inherit;
        padding: 0px;
        margin: 0px;
        font-size: inherit;
        line-height: 1.42857143;
        color: #333;
        word-break: break-all;
        word-wrap: break-word;
        background-color: transparent;
        border: 0px solid #ccc;
        border-radius: 0px;
        text-align: left;
        flex-basis: fit-content;
        flex-direction: column;
    }
    .body{
        text-align: left;
    }
  }

  .w-100 {
    width: 100%;
  }

.d-flex {
    display: flex;
}
.flex-column {
    flex-direction: column;
}

.gap-1 {
    gap: 1rem;
}

.gap-2 {
    gap: 2rem;
}

.cursor-pointer {
    cursor: pointer;
}